<template>
    <!--    按门店设置投放广告-->
    <div class="StoreToAdv">
        <el-card>
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-row>
                <el-col :span="6">
                    <el-row>
                        <el-button size="small" type="primary" plain @click="handlePickUpAdvs">选择广告</el-button>
                        <el-button size="small" type="primary" plain @click="handlePickUpManagedDept"
                            >选择门店</el-button
                        >
                        <el-button size="small" type="primary" @click="handleSave">保存</el-button>
                    </el-row>
                </el-col>
                <el-col :span="18">
                    <el-tag type="warning"><i class="el-icon-circle-check" />选择广告</el-tag>
                    <el-tag type="warning">设置投放日期</el-tag>
                    <el-tag type="warning"><i class="el-icon-warning-outline" />选择门店</el-tag>
                    <el-tag type="info">保存</el-tag>
                </el-col>
            </el-row>
        </el-card>
        <el-form
            @submit.native.prevent
            :inline="true"
            :model="form"
            :rules="rules"
            ref="createForm"
            label-width="80px"
            size="small"
        >
            <el-card shadow="never" style="margin-top: 8px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item prop="adCode" label="广告">
                            <span>code: </span>
                            <span>
                                <el-input type="text" :value="form.adCode" readonly style="width: 220px" />
                            </span>
                            <br />
                            <span>广告名称: </span><span>{{ model.adv.name }}</span>
                            <br />
                            <span>投放客户: </span><span>{{ model.adv.customerName }}</span>
                            <br />
                            <span>广告信息: </span>
                            <span>{{
                                `${advertFileTypeDesc(model.adv.type)}  ${model.adv.timeLength || '--'}秒`
                            }}</span>
                            <br />
                            <span>广告描述:{{ model.adv.content }}</span>
                        </el-form-item>
                        <el-form-item prop="position" label="广告位置">
                            <el-radio-group v-model.number="model.position" :rules="rules.position">
                                <el-radio :label="1">收银机主屏</el-radio>
                                <el-radio :label="2">收银机副屏</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="设置投放日期" label-width="120px" prop="startTime">
                            <el-date-picker
                                v-model="model.dateRange"
                                type="daterange"
                                align="right"
                                unlink-panels
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                value-format="yyyy-MM-dd"
                                :picker-options="pickerOptions"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-image
                            v-if="isImgAdvertFileType(model.adv.type)"
                            style="width: 100px; height: 100px"
                            :src="model.adv.uploadAccessory"
                            :preview-src-list="[model.adv.uploadAccessory]"
                        />
                        <video
                            v-else-if="isVideoAdvertFileType(model.adv.type)"
                            style="width: 100px; height: 100px"
                            :src="model.adv.uploadAccessory"
                        />
                        <el-button
                            size="mini"
                            type="primary"
                            :disabled="!model.adv.uploadAccessory"
                            @click="handlePreview(model.adv.type, model.adv.uploadAccessory)"
                            >预览</el-button
                        >
                    </el-col>
                </el-row>
            </el-card>
            <el-card shadow="never" style="margin-top: 8px">
                <div slot="header">
                    <span>已选择门店列表</span>
                </div>
                <el-row>
                    <el-form-item label="已选择门店数量" prop="deptCount" :rules="rules.deptCount" label-width="140px">
                        <el-input type="number" readonly :value="form.deptCount" />
                    </el-form-item>
                </el-row>
                <el-table
                    id="stores"
                    border
                    stripe
                    ref="stores"
                    :data="model.depts"
                    size="mini"
                    :highlight-current-row="true"
                    max-height="440"
                >
                    <el-table-column label="序号" type="index" width="60" align="center" />
                    <el-table-column label="部门名称" width="200" prop="name" />
                    <el-table-column label="类型" width="120" prop="type">
                        <template slot-scope="scope">
                            <span>{{ scope.row.type | deptType }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="省份" width="120" prop="provinceName" />
                    <el-table-column label="地市" width="80" prop="cityName" />
                    <el-table-column label="区县" width="80" prop="districtName" />
                    <el-table-column label="地址" min-width="240" prop="address" />
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button size="small" type="danger" plain @click="handleDelete(scope.$index)"
                                >删除</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>
        </el-form>
        <pick-up-advs ref="pickUpAdvs" @ok="handleSelectAdvsOk" :multi="false" />
        <pick-up-managed-dept ref="pickUpManagedDept" @ok="handleSelectManagedDeptOk" />
        <!--预览广告组件-->
        <advert-preview ref="advertPreview" />
    </div>
</template>

<script>
import PickUpAdvs from '../../../components/PickUpAdvs';
import PickUpManagedDept from '../../../components/PickUpManagedDept';
import AdvertUtils from 'js/AdvertUtils';
import { postAfterConfirmed } from 'request/http';
import AdvertPreview from 'views/menu/advert/manage/AdvertPreview';

export default {
    name: 'StoreToAdv',
    components: { PickUpAdvs, PickUpManagedDept, AdvertPreview },
    data() {
        return {
            model: {
                depts: [],
                adv: {},
                dateRange: [],
                position: 1,
            },
            rules: {
                adCode: { required: true, message: '请选择广告', trigger: 'change' },
                startTime: { required: true, message: '请选择投放日期', trigger: ['change'] },
                deptCount: { type: 'number', required: true, min: 1, message: '请选择门店', trigger: 'change' },
                position: { required: true, message: '请选择广告位置', trigger: 'change' },
            },
            pickerOptions: {
                shortcuts: [
                    {
                        text: '未来一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            end.setTime(start.getTime() + 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        },
                    },
                    {
                        text: '未来一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            end.setTime(start.getTime() + 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        },
                    },
                    {
                        text: '未来三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            end.setTime(start.getTime() + 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        },
                    },
                ],
            },
        };
    },
    computed: {
        form() {
            const deptCodes = this.model.depts?.map((e) => e.code) || [];
            return {
                adCode: this.model.adv?.code,
                startTime: this.model.dateRange?.[0],
                endTime: this.model.dateRange?.[1],
                deptCodes: deptCodes,
                deptCount: deptCodes.length,
                position: this.model.position,
            };
        },
    },
    methods: {
        isImgAdvertFileType(advertFileType) {
            return AdvertUtils.isImgAdvertFileType(advertFileType);
        },
        isVideoAdvertFileType(advertFileType) {
            return AdvertUtils.isVideoAdvertFileType(advertFileType);
        },
        advertFileTypeDesc(advertFileType) {
            return AdvertUtils.FileTypeEnum.properties[advertFileType]?.desc || '';
        },
        handlePickUpAdvs() {
            this.$refs.pickUpAdvs.show();
        },
        handleSelectAdvsOk(advs = []) {
            //由于是选择单个广告，所以只取一个
            this.model.adv = advs[0] || '';
        },
        handlePickUpManagedDept() {
            this.$refs.pickUpManagedDept.show();
        },
        handleSelectManagedDeptOk(depts = []) {
            //    过滤出未添加过的门店，并追加到已选择门店列表中
            this.model.depts ||= [];
            const noneSelectedDeptArr = depts.filter((e) => !this.model.depts.find((e1) => e1.code === e.code));
            this.model.depts.push(...noneSelectedDeptArr);
        },
        handleDelete(idx) {
            this.model.depts.splice(idx, 1);
        },
        handlePreview(advertFileType, advertUrl) {
            this.$refs.advertPreview.showDialog(advertFileType, advertUrl);
        },
        handleSave() {
            this.$refs.createForm.validate(async (valid) => {
                if (!valid) {
                    this.$message.error('填写内容有误，请检查所填写的所有数据');
                    return false;
                }
                await postAfterConfirmed('/advert/launch/createByAdvert', this.form);
                this.goBackAndReload();
            });
        },
    },
};
</script>

<style scoped>
.StoreToAdv .el-tag {
    margin-left: 8px;
}
</style>
